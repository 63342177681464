








































































































































	import { Component, Vue } from 'vue-property-decorator';
	import { internet } from '@/utils/Internet';
	import Util from '@/utils/Util';
	import { Icon } from '@/model/util/Icon';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	@Component({
		name: 'MainAudioDetail',
		components: {
			AppContainerBase,
		},
	})
	export default class MainAudioDetail extends Vue {
		public dialogProgress = this.$t("MainTags.dialogProgress");
		//text
		public titleAudioDetail = this.$t("audioDetail.titleAudioDetail");
		public btnVolver = this.$t("audioDetail.btnVolver");
		public btnTranscribir = this.$t("audioDetail.btnTranscribir");
		public btnAnalizar = this.$t("audioDetail.btnAnalizar");
		public cardTitle = this.$t("audioDetail.cardTitle");
		public cardText = this.$t("audioDetail.cardText");
		public btnCerrar = this.$t("audioDetail.btnCerrar");
		public btnContinuar = this.$t("audioDetail.btnContinuar");
		public cardTitleAnalisis = this.$t("audioDetail.cardTitleAnalisis");
		public selectScript = this.$t("audioDetail.selectScript");
		public subtiSelectScript = this.$t("audioDetail.subtiSelectScript");
		public selectTiLlamado = this.$t("audioDetail.selectTiLlamado");
		public subtiSelectTiLlamado = this.$t("audioDetail.subtiSelectTiLlamado");
		public dialog_transcrip = false;
		public dialog_analisis = false; 
		public dialog_progress = false; 
		public items = [
			this.$t("audioDetail.audioName"),
			this.$t("audioDetail.agentName"),
			this.$t("audioDetail.analizado"),
			//this.$t("ResultEvalText.item1"),
		];
		public scriptsTipoLlamada: any = {};
		public script: Array<string> = [];
		public tipo_llamada: Array<string> = [];
		public script_selected = "";
		public tipo_llamada_selected = "";
		public agente = "";
		public audio = "";
		public ruta = "";
		public audio_path = "";
		public token = "";
		public transcripcion = "";
		public analizado = "";
		public isLoading = false;

		mounted() {
			if (localStorage.getItem('TOKEN') != null) {
				this.token = localStorage.getItem('TOKEN') as string;
			}
			this.audio_path = process.env.VUE_APP_API;
			this.agente = this.$route.params.nombre_agente;
			this.audio = this.$route.params.nombre_audio;
			this.ruta = this.$route.params.ruta_audio;

			this.obtenerScripts();
			this.obtenerTranscripcion();
		}

		private obtenerTranscripcion() {
			this.isLoading = true;
			internet
                .newRequest()
                .get(`biblioteca/transcripcion?nombre_audio=${this.audio}`)
                .then((response) => {
					//console.log(response.data);
					this.analizado = response.data.analizado ? "Si" : "No";
					if (response.data.script != "") {
						this.script_selected = response.data.script;
					}
					if (response.data.tipo_llamada != "") {
						this.tipo_llamada = this.scriptsTipoLlamada[this.script_selected];
						this.tipo_llamada_selected = response.data.tipo_llamada;
					}
					if (response.data.mensaje == "Transcripcion encontrada") {
						this.transcripcion = response.data.transcripcion;
					} else {
						this.transcripcion = response.data.mensaje;
					}
                })
				.catch(() => {
					console.log("Error al cargar la transcripcion")
				})
				.finally(() => {
					this.isLoading = false;
				});		
		}


		private obtenerScripts() {
			internet
                .newRequest()
                .get("biblioteca/getScriptsTipoLlam")
                .then((response) => {
					//console.log(response.data);
					this.scriptsTipoLlamada = response.data;
					this.script = Object.keys(this.scriptsTipoLlamada) as Array<string>;
                })
				.catch(() => {
					console.log("Error al cargar los scripts");
				})
		}


		public changeScript() {
			this.tipo_llamada = this.scriptsTipoLlamada[this.script_selected];
		}


		public transcribir() {
			this.dialog_transcrip = false;
			this.dialog_progress = true;
			internet
                .newRequest()
                .post(`biblioteca/transcribirWhisper?nombre_audio=${this.audio}`)
                .then((response) => {
					//console.log(response.data);
					if (response.data.mensaje == "Audio transcrito correctamente") {
						this.transcripcion = response.data.transcripcion;
						this.dialog_progress = false;
						Util.showMessage(
							'Audio transcrito con exito',
							Icon.SUCCESS
						);
					} else {
						this.dialog_progress = false;
						Util.showMessage(
							'Error al transcribir el audio',
							Icon.ERROR
						);
					}
                })
				.catch(() => {
					console.log("Error al transcribir el audio");
				})
		}


		public analizar() {
			this.dialog_analisis = false;
			this.dialog_progress = true;
			internet
                .newRequest()
                .post(`biblioteca/analizar?nombre_audio=${this.audio}&nombre_script=${this.script_selected}&tipo_llamada=${this.tipo_llamada_selected}`)
                .then((response) => {
					//console.log(response.data);
					this.analizado = "Si";
					if (response.data.mensaje == "Audio transcrito y analizado correctamente") {
						this.transcripcion = response.data.transcripcion;
					}
					this.dialog_progress = false;
					Util.showMessage(
						'Audio analizado con exito',
						Icon.SUCCESS
					);
                })
				.catch(() => {
					console.log("Error al analizar el audio");
					this.dialog_progress = false;
					Util.showMessage(
						"Error al analizar el audio", 
						Icon.ERROR
					);
				})
		}

		public back(){
			this.$router.push({
				name: 'MainAudioSearch',
			})
		}

    }
